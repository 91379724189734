<template>
  <div class="details">
    <div class="title">{{data.cname}}</div>
    <div class="line" v-if="data.cname">
      <div class="line-title">【 检查名称 】</div>
      <div class="line-text" v-html="data.cname"></div>
    </div>
    <div class="line" v-if="data.category_path">
      <div class="line-title">【 分&nbsp&nbsp&nbsp&nbsp&nbsp类 】</div>
      <div class="line-text" v-html="data.category_path"></div>
    </div>
    <div class="line" v-if="data.alias">
      <div class="line-title">【 别&nbsp&nbsp&nbsp&nbsp&nbsp名 】</div>
      <div class="line-text" v-html="data.alias"></div>
    </div>
    <div class="column" v-if="data.summarization">
      <div class="line-title">【 概&nbsp&nbsp&nbsp&nbsp&nbsp述 】</div>
      <div class="line-text" v-html="data.summarization"></div>
    </div>
    <div class="column" v-if="data.principle">
      <div class="line-title">【 原&nbsp&nbsp&nbsp&nbsp&nbsp理 】</div>
      <div class="line-text" v-html="data.principle"></div>
    </div>
    <div class="column" v-if="data.reagent">
      <div class="line-title">【 试&nbsp&nbsp&nbsp&nbsp&nbsp剂 】</div>
      <div class="line-text" v-html="data.reagent"></div>
    </div>
    <div class="column" v-if="data.operation">
      <div class="line-title">【 操作方法 】</div>
      <div class="line-text" v-html="data.operation"></div>
    </div>
    <div class="column" v-if="data.normalize">
      <div class="line-title">【 正&nbsp常&nbsp值 】</div>
      <div class="line-text" v-html="data.normalize"></div>
    </div>
    <div class="column" v-if="data.meaning">
      <div class="line-title">【 临床意义 】</div>
      <div class="line-text" v-html="data.meaning"></div>
    </div>
    <div class="column" v-if="data.notes">
      <div class="line-title">【 附&nbsp&nbsp&nbsp&nbsp&nbsp注 】</div>
      <div class="line-text" v-html="data.notes"></div>
    </div>

    <div class="line" v-if="data.download">
      <div class="line-title">【 操&nbsp&nbsp&nbsp&nbsp&nbsp作 】</div>
      <div class="line-text">
        <a href="" @click.prevent="onDownload(data, '4')">下 载</a>
      </div>
    </div>
    <div class="foot-details" v-if="data.disease">
      <div class="line-title">【 相关疾病 】</div>
      <div class="line-text">
        <ul v-html="data.disease"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.video">
      <div class="line-title">【 相关视频 】</div>
      <div class="line-text">
        <ul v-html="data.video"></ul>
      </div>
    </div>

  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    name: "diagnosticExaminationDisease",
    mixins: [
      mixins
    ],
    data(){
      return {
        type: '4',
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        this.getData({ id: this.$route.query.id || this.$route.query.ID, type: this.type })
      }
    }
  }
</script>

